<template>
  <div class="flix-form-group">
    <label
      >{{ $t("design.color.title") }}
      <help>{{ $t("design.color.info") }}</help></label
    >
    <colorPicker :value="color" :callback="setColor" />
  </div>
</template>
<script>
export default {
  components: {
    colorPicker() {
      return import("@/components/default/colorpicker/");
    }
  },
  props: {},
  data() {
    return {
      color: this.$store.state.business.unsaved.styling.accent
    };
  },
  computed: {},
  mounted() {},
  methods: {
    setColor(c) {
      this.color = c;
      this.setSave();
    },
    setSave() {
      var data = JSON.parse(JSON.stringify(this.$store.state.business.unsaved));
      data.styling.accent = this.color;
      this.$store.commit("business/prefetch", data);
    }
  }
};
</script>
<style lang="sass" scoped>
label
  display: inline-block
  margin-bottom: 5px
</style>
